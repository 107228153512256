/*------------------------------------
    Variables
------------------------------------*/

:root {
  --gray: #eee;
  --white: #fff;
  --black: #000;
  --base-0: #fff;
  --base-1: #151812;
  --base-2: "";
  --base-3: "";
  --primary: orange;
  --primary-1: #ff8800;
  --secondary: "";
  --secondary-1: "";
  --color-1: #70b6fe;
  --color-2: #c670fe;
  --color-3: #fec070;
  --color-4: #dc3545;
  --color-5: #28a745;
  --color-6: darkblue;
}

// Colors
$gray: var(--gray);
$white: var(--white);
$black: var(--black);
$base-0: var(--base-0);
$base-1: var(--base-1);
$base-2: var(--base-2);
$base-3: var(--base-3);
$primary: var(--primary);
$primary-1: var(--primary-1);
$secondary: var(--secondary);
$secondary-1: var(--secondary-1);
$text-color: $base-1;
$color-1: var(--color-1);
$color-2: var(--color-2);
$color-3: var(--color-3);
$color-4: var(--color-4);
$color-5: var(--color-5);
$color-6: var(--color-6);
$success: green;

// Font Family
$font-family-1: "Poppins", sans-serif;
$font-family-2: $font-family-1;

// Grid
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1230px;
$space: 120px;

// Heading
$h1: 60px;
$h2: 54px;
$h3: 24px;
$h4: 20px;
$h5: 18px;
$h6: 16px;
$heading-font: $font-family-1;
$heading-color: $base-1;

// Container Width
$container-xs: 480px;
$container-sm: 750px;
$container-md: 970px;
$container-lg: 1200px;
$container-xl: 1800px;
