/* .portfolio_btns {
  display: flex;
  justify-content: space-around;
}

.portfolio_btns button {
  border: 1px solid #808c80;
  border-radius: 0.2rem;
  width: 6rem;
  line-height: 2rem;
  font-weight: 700;
}
 */

.portfolio_btns {
  display: flex;
  justify-content: space-around;
}

.portfolio_btns button {
  cursor: pointer;

  outline: none;
  border: none;
  font-weight: 700;
  border-radius: 0.4vw;
  padding: 0 2rem;
  /* //may need adjustign */
  margin-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 2px;
}

.portfolio_btns_code {
  background-color: rgba(128, 140, 128);
  color: white;
}

.portfolio_btns_code:hover {
  background-color: rgba(128, 140, 128, 0.8);
  transition: all 0.2s;
  cursor: pointer;
}

.portfolio_btns_demo {
  background-color: rgba(119, 119, 119, 0.9);
  color: white;
}

.portfolio_btns_demo:hover {
  background-color: rgba(109, 109, 110, 0.6);
  transition: all 0.2s;
  cursor: pointer;
}
